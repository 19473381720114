<template>
  <teleport to="body">
    <!-- position absolute is a fix for svgs taking space in browser, even there is only defs -->
    <svg viewBox="0 0 1 1" width="0" height="0" class="position-absolute">
      <defs>
        <linearGradient
          id="gp-gradient"
          x1=".5"
          x2=".5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#ffc75e" />
          <stop offset="1" stop-color="#f03da2" />
        </linearGradient>
      </defs>
    </svg>
  </teleport>
</template>
